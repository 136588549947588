import {IdType} from '../../util/models/IdType'
import {DisplaySet} from '../../displaySet/model/DisplaySet'

export interface DisplaySetConfiguration {
    id: IdType,
    displayOrder: number,
    displaySetConfigurations?: DisplaySetConfiguration[],
    disable: boolean
}

export const buildDisplaySetConfigurations = (displaySets: DisplaySet[] | undefined): DisplaySetConfiguration[] | undefined => {
    if(!displaySets) return undefined
    return displaySets.map((displaySet, index) => {
        return {
            id: displaySet.id,
            displayOrder: index,
            displaySetConfigurations: buildDisplaySetConfigurations(displaySet.children),
            disable: false
        }
    })
}

// deeply duplicate the objects to be able to update them
export const sanitizeAndSortDisplaySetsAndConfiguration = (displaySets: DisplaySet[] | undefined,  configurations: DisplaySetConfiguration[] | undefined): DisplaySet[] => {
    const duplicatedDisplaySets = duplicateDisplaySets(displaySets)
    return sortDisplaySetsFromConfigurations(duplicatedDisplaySets, configurations) ?? []
}

export const duplicateDisplaySets = (displaySets: DisplaySet[] | undefined): DisplaySet[] | undefined => {
    return displaySets?.map(displaySet => {
        return {
            id: displaySet.id,
            displayOrder: displaySet.displayOrder,
            label:
            displaySet.label,
            attributes:
            displaySet.attributes,
            children: duplicateDisplaySets(displaySet.children),
            widgetId: displaySet.widgetId,
            iconName: displaySet.iconName
        }
    })
}

export const sortDisplaySetsFromConfigurations = (displaySets: DisplaySet[] | undefined, configurations: DisplaySetConfiguration[] | undefined): DisplaySet[] | undefined => {
    if(!displaySets) return undefined
    if(configurations){
        const configurationsById: Map<IdType, DisplaySetConfiguration> = new Map(configurations.map(configuration => [configuration.id, configuration]))
        displaySets.forEach(displaySet => {
            const configurationForDisplaySet = configurationsById.get(displaySet.id)
            displaySet.displayOrder = configurationForDisplaySet?.displayOrder ?? displaySet.displayOrder
            displaySet.children = sortDisplaySetsFromConfigurations(displaySet.children, configurationForDisplaySet?.displaySetConfigurations)
        })
    }
    return displaySets.sort((dsA, dsB) => dsA.displayOrder - dsB.displayOrder)
}
