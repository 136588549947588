import React, {useContext, useState} from 'react'
import './ItemDetailsContent.scss'
import {Typography} from '@mui/material'
import './ItemContentDialog.scss'
import appInsights from '../../../util/tracking/appInsight'
import ItemServiceDialog from './ItemServiceDialog'
import {BillingTypeEnum, InformationPackage} from '../../model/InformationPackage'
import {selectCurrentUser} from '../../../user/rtkUserApi'
import {useSelector} from 'react-redux'
import {SimpleItem} from '../../model/SimpleItem'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../util/routing'
import {AttributeModel} from '../../../displaySet/model/AttributeModel'
import {DisplaySetTreeComponent} from '../../../displaySet/DisplaySetTreeComponent'
import {DisplaySetContext} from '../../../displaySet/DisplaySetContext'
import EditNoteIcon from '@mui/icons-material/EditNote'
import {Link} from 'react-router-dom'
import {ROUTES} from '../../../constants/routing'
import {ItemDetailView} from '../itemDetailsPage/ItemDetailToggleButton'


const ItemDetailsContent = ({item, selectedItemDetailView}: {
    item: SimpleItem,
    selectedItemDetailView?: ItemDetailView
}) => {
    const {selectedDisplaySet} = useContext(DisplaySetContext)

    const [currentGuildContext] = useCurrentGuildContext()
    const user = useSelector(selectCurrentUser)

    const attributes: AttributeModel[] = []
    const [getModalContent, setModalContent] = useState({
        informationPackage: {
            attributes,
            id: '',
            label: '',
            displayOrder: 0,
            billingType: BillingTypeEnum.TO_BUY,
        },
    })
    const [isModalOpen, setModalOpen] = useState(false)

    const isPackageAccessDisabled = !user || !currentGuildContext

    const performModalServiceInterest = () => {
        appInsights.trackEvent({
            name: 'Service Interest',
            properties: {
                serviceName: getModalContent.informationPackage.label,
            },
        })
    }
    const toggleServiceModal = (informationPackage: InformationPackage) => {
        setModalContent({informationPackage})
        performModalServiceInterest()
        setModalOpen(true)
    }

    const generatePath = useDatxPathGenerator()

    return (
        <div className="item-details-content details-content details-page-section details-page-section" data-testid="test-details-header">
            {selectedItemDetailView === ItemDetailView.CUSTOMISED ? (
                <div className="customize-layout">
                    <div className="customize-layout-frame" data-testid="customised-layout-link">
                        <EditNoteIcon className="edit-icon"/>
                        <Link to={generatePath(ROUTES.displaySetConfiguration.path)}>
                            <Typography className="layout-text"> Customise Layout</Typography>
                        </Link>
                    </div>
                </div>
            ): null }
            <div className="grid">
                <div className="display-sets">
                    {selectedDisplaySet &&
                        <DisplaySetTreeComponent
                            key={selectedDisplaySet?.displayOrder ?? Math.random()}
                            displaySet={selectedDisplaySet} parentItem={item}
                        />
                    }
                </div>

                {/*<div className="item-right-panel">*/}
                {/*    <div className="display-set">*/}
                {/*        <div className="display-set-title">Request an Information Package</div>*/}
                {/*        <div className="display-set-content-right">*/}
                {/*            {item?.notAccessibleInformationPackages?.map(*/}
                {/*                (notAccessibleInformationPackage) => {*/}
                {/*                    return (*/}
                {/*                        <div className="service-card" key={notAccessibleInformationPackage.label}>*/}
                {/*                            <p className="subtitle">{notAccessibleInformationPackage.label}</p>*/}
                {/*                            <p>Get all {notAccessibleInformationPackage.label} for this item now</p>*/}
                {/*                            /!*Empty string is used to not display the tooltip*!/*/}
                {/*                            <Tooltip*/}
                {/*                                title={*/}
                {/*                                    isPackageAccessDisabled*/}
                {/*                                        ? 'You have to select a guild in order to request access to an information package.'*/}
                {/*                                        : ''*/}
                {/*                                }*/}
                {/*                            >*/}
                {/*                                <span>*/}
                {/*                                    <Button*/}
                {/*                                        onClick={() =>*/}
                {/*                                            toggleServiceModal(notAccessibleInformationPackage)*/}
                {/*                                        }*/}
                {/*                                        variant="outlined"*/}
                {/*                                        color="primary"*/}
                {/*                                        startIcon={<Stars/>}*/}
                {/*                                        disabled={isPackageAccessDisabled}*/}
                {/*                                    >*/}
                {/*                                        Request Access*/}
                {/*                                    </Button>*/}
                {/*                                </span>*/}
                {/*                            </Tooltip>*/}
                {/*                        </div>*/}
                {/*                    )*/}
                {/*                },*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            {isModalOpen && (
                <ItemServiceDialog
                    closeDialog={() => setModalOpen(false)}
                    open={isModalOpen}
                    informationPackage={getModalContent.informationPackage}
                    itemId={item?.id ?? ''}
                />
            )}
        </div>
    )
}


export default ItemDetailsContent
