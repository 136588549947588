import {WorkflowConfiguration} from './model/WorkflowConfiguration'
import ServiceWorkflowConfiguration from './ServiceWorkflowConfiguration'
import React from 'react'
import {availableServices} from './WorkflowConfigurationManagement'
import {Member} from '../models/Member'
import {getServiceName} from '../../service/model/ServiceEnum'

const WorkflowConfigurationTable = ({ workflowConfigurations, guildUsers }: {
    workflowConfigurations: WorkflowConfiguration[],
    guildUsers: Member[]
}) => {

    const sortWorkflowsDefaultFirstCustomAlphabetical = (workflows: WorkflowConfiguration[]) => {
        if (workflows && workflows.length)
            return workflows.sort((a, b) =>
                ((+b.isDefault) - (+a.isDefault) || a.name.localeCompare(b.name))
            )
        return []
    }

    return (
        <>
            {availableServices.map((service) => {
                const existingConfigurations =
                    sortWorkflowsDefaultFirstCustomAlphabetical(
                        workflowConfigurations.filter((conf) => conf.service === service)
                    )

                return <div className="service-workflow-setup">
                    <div className="service-name" data-testid={`service-name ${getServiceName(service ?? '')}`}>
                        {getServiceName(service ?? '')}
                    </div>
                    {existingConfigurations.map(configuration =>
                        <ServiceWorkflowConfiguration
                            key={`id-${configuration.id}`}
                            workflowConfiguration={
                                //Unsure if all guilds have their own workflow configurations for all available services in the database
                                //=> iterating through available services to display all available services in frontend
                                configuration
                            }
                            guildUsers={guildUsers}
                        />
                    )}
                </div>
            })}
        </>
    )
}

export default WorkflowConfigurationTable
