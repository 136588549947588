import React, {useMemo, useState} from 'react'
import {
    Button,
    Checkbox,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Radio,
    Select,
    SelectChangeEvent,
    TextField
} from '@mui/material'
import {ROUTES} from '../../../constants/routing'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../util/routing'
import {useNavigate} from 'react-router-dom'
import {useTypedDispatch} from '../../../util/store'
import {useCreateWorkflowConfigurationMutation, useGetGuildQuery} from '../../rtkGuildApi'
import {getServiceName} from '../../../service/model/ServiceEnum'
import {useFormik} from 'formik'
import {setSnackbarMessage} from '../../../genericComponents/commonSlice'
import {WorkflowConfiguration} from '../model/WorkflowConfiguration'
import {LoadingButton} from '@mui/lab'
import {Label} from '../../../genericComponents/typographie/Label'
import {availableServices} from '../WorkflowConfigurationManagement'
import TextFilterInputInSelect from './dropdownFilter/TextFilterInputInSelect'
import {IdType} from '../../../util/models/IdType'
import {string, object} from 'yup'
import CustomSwitch from '../../../genericComponents/customSwitch/CustomSwitch'
import {WorkflowConfigurationStage} from '../model/WorkflowConfigurationStage'
import { User } from '../../../user/model/User'
import { Member } from '../../models/Member'

const CreateServiceWorkflowConfiguration = () => {
    const generatePath = useDatxPathGenerator()
    const navigate = useNavigate()
    const dispatch = useTypedDispatch()
    const [currentGuildContext] = useCurrentGuildContext()
    const [createWorkflowConfiguration, createWorkflowResult] = useCreateWorkflowConfigurationMutation()

    const [searchText, setSearchText] = useState('')

    const {data: guild} = useGetGuildQuery(currentGuildContext?.guildId, { skip: !currentGuildContext?.guildId })
    const guildAdmins = guild?.guildUsers.filter((user) => user.admin)
    const guildMembers = guild?.guildUsers

    const [selectableApprovers, setSelectableApprovers] = React.useState<IdType[]>([])
    const [selectableRequestors, setSelectableRequestors] = React.useState<IdType[]>([])

    React.useMemo(() => {
        if (guild && guildAdmins && guildMembers) {
            setSelectableApprovers(guildAdmins?.map(admin => admin.user.id) ?? [])
            setSelectableRequestors(guildMembers?.map(user => user.user.id) ?? [])
        }
    }, [guild])

    const containsText = (text: string, searchText: string) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1

    const filteredGuildAdmins = useMemo(() => {
        return guildAdmins?.filter((adminUser) => containsText(adminUser.user.email, searchText)).map(admin => admin.user)
    }, [searchText, guild])
    const filteredGuildMembers = useMemo(() => {
        return guildMembers?.filter((guildUser) => containsText(guildUser.user.email, searchText)).map(member => member.user)
    }, [searchText, guild])

    const workflowInitialValues: WorkflowConfiguration = {
        id: '',
        guildId: currentGuildContext?.guildId ?? '',
        service: '',
        isDefault: false,
        name: '',
        quotationAutoApproval: false,
        quotationAutoApprovalThreshold: 0,
        serviceRequestAutoValidation: false,
        requestors: [],
        approvers: []
    }

    const formik = useFormik({
        initialValues: workflowInitialValues,
        enableReinitialize: true,
        validationSchema: object({
            service: string().required(),
            name: string().required()
        }),
        onSubmit: (values) => {
            handleCreateWorkflowConfiguration(values)
        }
    })

    const handleApproverChange = (event: SelectChangeEvent<string[]>) => {
        const { target: { value } } = event
        setSelectableApprovers(value as any)
    }

    const handleSelectAllApprovers = (event: any) => {
        if (event.target.checked) {
            setSelectableApprovers(guildAdmins?.map(admin => admin.user.id) ?? [])
        } else setSelectableApprovers([])
    }

    const getUsers = (selectedApproverIds: IdType[], allUsers: Member[] | undefined): User[] => {
        if (allUsers === undefined) return []
        return allUsers?.filter(member => selectedApproverIds.includes(member.user.id)).map(admin => admin.user)
    }

    const handleCreateWorkflowConfiguration = (values: WorkflowConfiguration) => {
        // todo: to be remove in future story:
        // set default values to All Approvers and Requestors
        values.approvers = getUsers(selectableApprovers, guildAdmins)
        values.requestors = getUsers(selectableRequestors, guildMembers)

        createWorkflowConfiguration({newWorkflowConfiguration: values})
            .unwrap()
            .then(() => {
                dispatch(setSnackbarMessage('New Workflow has been added'))
                navigate(generatePath(ROUTES.workflowConfiguration.path))
            })
    }

    return (
        <form onSubmit={formik.handleSubmit} className="workflow-configuration-container">
            <h1 className="workflow-configuration-title">Add New Workflow</h1>
            <div className="workflow-configuration-content">
                <div className="add-new-workflow-container">
                    <div className="workflow-service">
                        <Label
                            id="add-workflow-service-label"
                            className="workflow-label"
                            htmlFor="workflowServiceSelect"
                            required
                        >
                            Service
                        </Label>
                        <Select
                            data-testid="workflow-service-dropdown-selector"
                            id="workflow-service-dropdown-selector"
                            value={formik.values.service}
                            onChange={(event) => formik.setFieldValue('service', event?.target?.value)}
                            name="workflowServiceSelect"
                            placeholder="Choose Service"
                            displayEmpty
                            renderValue={(service) =>
                                service !== '' ? (getServiceName(service)) : <span className="placeholder">Choose Service</span>
                            }
                        >
                            {availableServices?.length &&
                                availableServices.map((service) => (
                                    <MenuItem
                                        className="service-items"
                                        value={service}
                                        key={service}
                                        autoFocus={false}
                                        data-cy={`select-option-${getServiceName(service)}`}
                                    >
                                        {getServiceName(service)}
                                    </MenuItem>
                                ))}
                        </Select>
                    </div>
                    <div className="workflow-name">
                        <Label
                            id="add-workflow-name-label"
                            className="workflow-label"
                            htmlFor="workflowNameTextInput"
                            required
                        >
                            Name of Workflow
                        </Label>
                        <TextField
                            data-testid="workflow-name-text-input"
                            value={formik.values.name}
                            placeholder="Input Workflow Name"
                            onChange={(e) => formik.setFieldValue('name', e?.target?.value)}
                        />
                    </div>
                    <div className="workflow-approvers">
                        <Label
                            id="add-workflow-request-approvers-label"
                            className="workflow-label"
                            htmlFor="workflowApproversSelect"
                            required
                        >
                            Approvers
                        </Label>
                        <Select
                            name="workflowApproversSelect"
                            labelId="workflow-approvers-select-label"
                            id="workflow-approvers-select"
                            multiple
                            value={selectableApprovers}
                            onChange={handleApproverChange}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.length === guildAdmins?.length ? 'All Guild Admins' : selected.length + ' selected'}
                            placeholder="Select"
                            MenuProps={{autoFocus: false}}
                            disabled
                        >
                            <div className="filter-input" role="group">
                                <TextFilterInputInSelect
                                    searchText={searchText}
                                    setSearchText={setSearchText}
                                    label="Search User"
                                ></TextFilterInputInSelect>
                            </div>
                            <MenuItem value="All Guild Admins" className="approver-items">
                                <Checkbox size="small" value="selectAll"
                                          checked={selectableApprovers.length === guildAdmins?.length}
                                          onChange={handleSelectAllApprovers} />
                                <ListItemText className="menu-item-label" primary="(Select All)" />
                            </MenuItem>

                            {filteredGuildAdmins?.map((option) => (
                                <MenuItem key={option.id} value={option.id} className="approver-items">
                                    <Checkbox size="small" checked={selectableApprovers.includes(option.id)} />
                                    <ListItemText className="menu-item-label" primary={option.email} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="workflow-requestors">
                        <Label
                            id="add-workflow-requestors-label"
                            className="workflow-label"
                            htmlFor="workflowRequestorsSelect"
                            required
                        >
                            Requestors
                        </Label>
                        <Select
                            name="workflowRequestorsSelect"
                            labelId="workflow-requestors-select-label"
                            id="workflow-requestors-select"
                            multiple
                            value={selectableRequestors}
                            onChange={formik.handleChange}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.length === guildMembers?.length ? 'All Guild Members' : selectableRequestors.join(', ')}
                            placeholder="Select"
                            MenuProps={{ autoFocus: false }}
                            disabled
                        >
                            <div className="filter-input" role="group">
                                <TextFilterInputInSelect
                                    searchText={searchText}
                                    setSearchText={setSearchText}
                                    label="Search User"
                                ></TextFilterInputInSelect>
                            </div>
                            <MenuItem value="All Guild Members" className="requestor-items">
                                <Checkbox size="small" checked={true} />
                                <Label className="select-all-label" htmlFor="select-all-requestors">
                                    (Select All)
                                </Label>
                            </MenuItem>
                            {filteredGuildMembers?.map((option) => (
                                <MenuItem value={option.id} className="requestor-items">
                                    <Checkbox size="small" checked={selectableRequestors.includes(option.id)} />
                                    <Label className="menu-item-label">{option.email}</Label>
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="workflow-request-approval-container">
                        <div className="edit-value">Request Approval</div>
                        <div>
                            <CustomSwitch
                                disabled={false}
                                value={WorkflowConfigurationStage.AUTO_APPROVE_SERVICE_REQUEST}
                                isChecked={formik.values.serviceRequestAutoValidation}
                                toggleChecked={(e) =>
                                    formik.setFieldValue('serviceRequestAutoValidation', e.target.checked)
                                }
                                dataTestid="workflow-auto-request-approval-toggle"
                            />
                        </div>
                    </div>

                    <div className="workflow-quotation-approval-container">
                        <div className="edit-value">Quotation Approval</div>
                        <div className="workflow-quotation-approval-toggle-radio-button">
                            <CustomSwitch
                                disabled={false}
                                value={WorkflowConfigurationStage.AUTO_APPROVE_QUOTATION}
                                isChecked={formik.values.quotationAutoApproval}
                                toggleChecked={(e) => formik.setFieldValue('quotationAutoApproval', e.target.checked)}
                                dataTestid="workflow-auto-quotation-approval-toggle"
                            />
                            {formik.values.quotationAutoApproval &&
                                <div className="quotation-options">
                                    <div className="radio-group">
                                        <Radio
                                            checked={formik.values.quotationAutoApprovalThreshold === null}
                                            onChange={() => formik.setFieldValue('quotationAutoApprovalThreshold', null)}
                                            disabled={!formik.values.quotationAutoApproval}
                                        />
                                        <span className="edit-title">Always</span>
                                    </div>
                                    <div className="radio-group">
                                        <Radio
                                            data-testid="quotation-input"
                                            checked={formik.values.quotationAutoApprovalThreshold !== null}
                                            onChange={() =>
                                                formik.setFieldValue('quotationAutoApprovalThreshold', 30) // Default value
                                            }
                                            disabled={!formik.values.quotationAutoApproval}
                                        />
                                        <span className="edit-title">When quotation is lower than:</span>
                                        <TextField
                                            data-testid="quotation-text-field"
                                            className="quotation-threshold-input"
                                            type="number"
                                            value={formik.values.quotationAutoApprovalThreshold || ''}
                                            onChange={(e) =>
                                                formik.setFieldValue('quotationAutoApprovalThreshold', e.target.value)
                                            }
                                            disabled={formik.values.quotationAutoApprovalThreshold === null}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="workflow-configuration-form-footer">
                    <Button
                        className="workflow-configuration-form-previous"
                        variant="text"
                        type="button"
                        onClick={() => navigate(generatePath(ROUTES.workflowConfiguration.path))}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        color="primary"
                        variant="contained"
                        type="submit"
                        className="workflow-configuration-form-next"
                        data-testid={'workflow-configuration-add-button'}
                        disabled={!formik.dirty || !formik.isValid}
                        loading={createWorkflowResult.isLoading}
                    >
                        Add Workflow
                    </LoadingButton>
                </div>
            </div>
        </form>
    )
}

export default CreateServiceWorkflowConfiguration
