import React from 'react'
import {useFormikContext} from 'formik'
import {AttributeStringUpdateForm} from '../PrivateAttributeUpdate'
import * as FileSaver from 'file-saver'
import {ServiceRequestStep} from '../../../genericComponents/formTemplate/steps/ServiceRequestStep'
import {Description} from '../../../../../genericComponents/typographie/Description'
import {Label} from '../../../../../genericComponents/typographie/Label'
import {Link} from '@mui/material'
import { AttributeDefinitionTypeEnum } from '../../../../../displaySet/model/AttributeModel'

export const ReviewAndSubmitStringUpdate = () => {
    const formik = useFormikContext<AttributeStringUpdateForm>()

    const getFile = () => {
        const file = formik?.values?.file
        if (!file) return
        FileSaver.saveAs(file, file.name)
    }

    const isArtifact = formik?.values?.privateDefinition?.type?.attributeDefinitionType === AttributeDefinitionTypeEnum.ARTIFACT

    return <ServiceRequestStep>
        <Description>Review the parameters of your selection and submit.</Description>
        <div className="service-request-form-summary">
            <Label className="form-summary-label">Private Attribute</Label>
            <span className="form-summary-value">{formik?.values?.privateDefinition?.name ?? '-'}</span>

            <Label className="form-summary-label">Attribute Type</Label>
            <span className="form-summary-value">{formik?.values?.privateDefinition?.type?.attributeDefinitionType ?? '-'}</span>

            <Label className="form-summary-label">Item List</Label>
            <span className="form-summary-value">{formik?.values?.itemList?.name ?? '-'}</span>

            {isArtifact ? (<>
            <Label className="form-summary-label"> Uploaded Document</Label>
            <Link onClick={getFile}
                  className="form-summary-value link"
                  data-testid="download-template">{formik?.values?.artifactFile?.name ?? '-'}
            </Link></>
                ):(
            <><Label className="form-summary-label"> Uploaded Template</Label>
            <Link onClick={getFile}
                  className="form-summary-value link"
                  data-testid="download-template">{formik?.values?.file?.name ?? '-'}
            </Link></>
                )}
        </div>
    </ServiceRequestStep>
}