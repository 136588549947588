import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import {WorkflowConfiguration} from './model/WorkflowConfiguration'
import {ROUTES} from '../../constants/routing'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../util/routing'
import {Link, useSearchParams} from 'react-router-dom'
import {IdType} from '../../util/models/IdType'
import {workflowGuildId} from '../../search/SearchParams'
import {getServiceName} from '../../service/model/ServiceEnum'
import React from 'react'
import NoAutoApproveStatus from './status/NoAutoApproveStatus'
import AutoApproveStatus from './status/AutoApproveStatus'
import ThresholdAutoApproveStatus from './status/ThresholdAutoApproveStatus'
import {Member} from '../models/Member'

const ServiceWorkflowConfigurationDetails = ({ workflowConfiguration, guildUsers }: {
    workflowConfiguration: WorkflowConfiguration,
    guildUsers: Member[]
}) => {

    const DEFAULT_DESCRIPTION = 'Default workflow will be chosen when no customised workflow is selected.'
    const CUSTOM_DESCRIPTION = 'This workflow will only be accessible to selected users.'
    const generatePath = useDatxPathGenerator()
    const [searchParams] = useSearchParams()
    const [currentGuildContext] = useCurrentGuildContext()

    const approvers = Array.from(workflowConfiguration.approvers)
    const requestors = Array.from(workflowConfiguration.requestors)

    const noApprovalStatus = 'No Auto Approve'
    const autoApprovalStatus = 'Always Auto Approve'
    const thresholdApprovalStatus = `<${workflowConfiguration.quotationAutoApprovalThreshold} Credits Auto Approve`

    const numberOfGuildAdmins = guildUsers.filter((user) => user.admin).length
    const numberOfGuildMembers = guildUsers.length

    const getRedirectPath = (id: IdType) => {
        if (!currentGuildContext) {
            return ''
        }
        searchParams.set(workflowGuildId, currentGuildContext.guildId)
        return {
            pathname: generatePath(ROUTES.editWorkflowConfiguration.path, { id: id }),
            search: `?${searchParams}`,
        }
    }

    const getStatusColorClass = (quotationAutoApproval: boolean | null, quotationAutoApprovalThreshold: number | null) => {
        if (quotationAutoApproval) {
            return quotationAutoApprovalThreshold !== null ? 'auto-approve-with-threshold' : 'always-auto-approve'
        } else {
            return 'no-auto-approve'
        }
    }

    const isDefaultWorkflow = () => {
        return workflowConfiguration.name === 'Default'
    }

    return (
            <div className={`service-configuration-container ${isDefaultWorkflow() ? 'default' : 'custom'}`}>
                <div className="first_container">
                    <div className="default_title">{workflowConfiguration.name}</div>
                    <Link
                        to={getRedirectPath(workflowConfiguration.id)}
                        className="edit-link"
                        data-testid={`edit-details-for-${getServiceName(workflowConfiguration.service ?? '')}`}
                    >
                        <EditOutlinedIcon className="edit_icon"/>
                    </Link>
                </div>
                <div className="workflow-description">{isDefaultWorkflow() ? DEFAULT_DESCRIPTION : CUSTOM_DESCRIPTION}</div>
                <div className="service-details">
                    <div className="service-configuration-detail">
                    <div className="details-label">Approvers:</div>
                        <div className="details-value">
                            {isDefaultWorkflow() || numberOfGuildAdmins === approvers.length
                                ? 'All Guild Admins' : `${approvers.length} Guild Admins`}
                        </div>
                    </div>
                    <div className="service-configuration-detail">
                        <div className="details-label">Requestors:</div>
                            <div className="details-value">
                                {isDefaultWorkflow() || numberOfGuildMembers === requestors.length
                                    ? 'All Guild Members' : `${requestors.length} Guild Members`}
                            </div>
                        </div>
                        <div
                            className={`service-configuration-detail-request ${workflowConfiguration.serviceRequestAutoValidation ? 'request-always-approved' : 'request-not-approved'}`}
                            data-testid={`auto-request-selection-for-${getServiceName(workflowConfiguration.service ?? '')}`}>
                            <div className="details-label">Request</div>
                            {workflowConfiguration.serviceRequestAutoValidation ? (
                                <AutoApproveStatus autoApprovalStatus={autoApprovalStatus}/>
                            ) : (
                                <NoAutoApproveStatus noApprovalStatus={noApprovalStatus}/>
                            )}
                        </div>
                        <div
                            className={`service-configuration-detail-quotation ${getStatusColorClass(workflowConfiguration.quotationAutoApproval, workflowConfiguration.quotationAutoApprovalThreshold)}`}
                            data-testid={`auto-quotation-selection-for-${getServiceName(workflowConfiguration.service ?? '')}`}>
                            <div className="details-label">Quotation</div>
                            {!workflowConfiguration.quotationAutoApproval ? (
                                <NoAutoApproveStatus noApprovalStatus={noApprovalStatus}/>
                            ) : workflowConfiguration.quotationAutoApprovalThreshold ? (
                                <ThresholdAutoApproveStatus thresholdApprovalStatus={thresholdApprovalStatus}/>
                            ) : (
                                <AutoApproveStatus autoApprovalStatus={autoApprovalStatus}/>
                            )}
                        </div>
                    </div>
                </div>
                )
                }

                export default ServiceWorkflowConfigurationDetails
