import {PageTitle} from '../../genericComponents/typographie/PageTitle'
import React, {useContext} from 'react'
import './DisplaySetConfigurationPage.scss'
import {Description} from '../../genericComponents/typographie/Description'
import {DisplaySetContext, DisplaySetProvider} from '../../displaySet/DisplaySetContext'
import {DisplaySetTabsWrapperComponent} from '../../displaySet/DisplaySetTabsWrapperComponent'
import {useGetDisplaySetTreeQuery} from '../../displaySet/rtkDisplaySet'
import LoadingSpinner from '../../genericComponents/LoadingSpinner'
import {DisplaySetConfigurationTab} from './DisplaySetConfigurationTreeComponent'
import {LoadingButton} from '@mui/lab'
import {useSelector} from 'react-redux'
import {selectCurrentUser, useUpdateCurrentUserInfoMutation} from '../rtkUserApi'
import {
    buildDisplaySetConfigurations,
    sanitizeAndSortDisplaySetsAndConfiguration,
} from '../model/DisplaySetConfiguration'
import {buildUserProfileUpdate} from '../model/User'
import {useNavigate} from 'react-router-dom'
import BackButton from '../../util/component/button/backButton/BackButton'

export const DisplaySetConfigurationPage = () => {
    const user = useSelector(selectCurrentUser)
    const {data: displaySets, isFetching: isFetchingDisplaySets} = useGetDisplaySetTreeQuery()
    const [updateUser, updateUserResult] = useUpdateCurrentUserInfoMutation()

    const sortedDisplaySets = sanitizeAndSortDisplaySetsAndConfiguration(displaySets, user?.displaySetConfigurations)

    const onSubmit = () => {
        if(!user) return
        const displaySetsConfigurations = buildDisplaySetConfigurations(sortedDisplaySets)
        const newUserProfile = buildUserProfileUpdate({user: user, displaySetConfigurations: displaySetsConfigurations})
        updateUser({user: newUserProfile})
    }

    const onReset = () => {
        if(!user) return
        updateUser({user: buildUserProfileUpdate({user: user, displaySetConfigurations: []})})
    }
    const navigate = useNavigate()
    const backToItemDetails = () => {
        navigate(-1)
    }

    return <div className="display-set-configuration-page">

        <BackButton text="Back to Item Details" onClick={backToItemDetails}/>
        <div className="display-set-configuration-header">
            <PageTitle title="Customize Your Item Detail Page View"></PageTitle>
            <Description>** Adjustments to layout will apply to all items in DATX</Description>
        </div>
        <div className="instructions-wrapper">
            <img className="instructions" src="/images/user/displaySetCustomizationInstructions.svg"
                 alt="Display Set Customization Instructions"/>
        </div>

        {isFetchingDisplaySets ? <LoadingSpinner/> :
            <div className="display-sets">
                <DisplaySetProvider displaySets={sortedDisplaySets}>
                    <DisplaySetTabsWrapperComponent displaySets={sortedDisplaySets}
                                                    defaultTabName={'Item Details'}
                    >
                        <DisplaySetsConfiguration></DisplaySetsConfiguration>
                    </DisplaySetTabsWrapperComponent>
                </DisplaySetProvider>
                <div className="display-sets-buttons">
                    <LoadingButton variant="outlined" color="secondary" type="submit" className="reset-button"
                                   disabled={updateUserResult.isLoading}
                                   onClick={onReset}>
                        Reset
                    </LoadingButton>
                    <LoadingButton variant="contained" color="primary" type="submit" className="save-button"
                                   disabled={updateUserResult.isLoading}
                                   onClick={onSubmit}>
                        Save Layout
                    </LoadingButton>
                </div>
            </div>}

    </div>
}

const DisplaySetsConfiguration = () => {
    const {selectedDisplaySet} = useContext(DisplaySetContext)

    if (!selectedDisplaySet) return <></>
    return <>
        <DisplaySetConfigurationTab
            key={selectedDisplaySet?.displayOrder ?? Math.random()}
            displaySet={selectedDisplaySet}
        />
    </>
}
