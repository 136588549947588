import React, {useEffect} from 'react'
import {Button, Radio, TextField} from '@mui/material'
import {ROUTES} from '../../constants/routing'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../util/routing'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useTypedDispatch} from '../../util/store'
import {
    useGetGuildQuery,
    useGetWorkflowConfigurationQuery,
    useUpdateWorkflowConfigurationMutation
} from '../rtkGuildApi'
import {getServiceName, ServiceEnum} from '../../service/model/ServiceEnum'
import {useFormik} from 'formik'
import {setSnackbarMessage} from '../../genericComponents/commonSlice'
import CustomSwitch from '../../genericComponents/customSwitch/CustomSwitch'
import {WorkflowConfigurationStage} from './model/WorkflowConfigurationStage'
import {IdType} from '../../util/models/IdType'
import {WorkflowConfiguration} from './model/WorkflowConfiguration'
import {workflowGuildId} from '../../search/SearchParams'
import {LoadingButton} from '@mui/lab'
import {WarningAmberOutlined} from '@mui/icons-material'

const initialValues: WorkflowConfiguration = {
    id: '',
    guildId: '',
    service: ServiceEnum.ITEM_MONITORING,
    isDefault: false,
    name: '',
    quotationAutoApproval: false,
    quotationAutoApprovalThreshold: 20,
    serviceRequestAutoValidation: false,
    requestors: [],
    approvers: [],
}

const ServiceWorkflowConfigurationEditDetails = () => {

    const generatePath = useDatxPathGenerator()
    const navigate = useNavigate()
    const dispatch = useTypedDispatch()
    const { id } = useParams()
    const [searchParams] = useSearchParams()

    const [currentGuildContext] = useCurrentGuildContext()

    const { data: workflowConfiguration } = useGetWorkflowConfigurationQuery(id as IdType)
    const [updateWorkflowConfiguration] = useUpdateWorkflowConfigurationMutation()
    const {data: guild} = useGetGuildQuery(currentGuildContext?.guildId, { skip: !currentGuildContext?.guildId })

    useEffect(() => {
        if (
            currentGuildContext?.guildId &&
            currentGuildContext.guildId !== searchParams.get(workflowGuildId)
        ) {
            navigate(generatePath(ROUTES.workflowConfiguration.path))
        }
    }, [currentGuildContext?.guildId])

    const formik = useFormik({
        initialValues : workflowConfiguration || initialValues,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleUpdateWorkflowConfiguration(values)
        },
    })

    const handleUpdateWorkflowConfiguration = (values: WorkflowConfiguration) => {
        updateWorkflowConfiguration({workflowConfiguration: values})
            .unwrap()
            .then(() => {
                dispatch(setSnackbarMessage('Workflow configuration has been saved'))
                navigate(generatePath(ROUTES.workflowConfiguration.path))
            })
            .catch(() => {
                formik.setFieldValue('quotationAutoApproval', false)
            })
    }

    const guildUsers = guild?.guildUsers ?? []
    const numberOfGuildAdmins = guildUsers.filter((user) => user.admin).length
    const numberOfGuildMembers = guildUsers.length

    const approvers = Array.from(formik.values.approvers)
    const requestors = Array.from(formik.values.requestors)

    const isDefaultWorkflow = () => {
        return formik.values.name === 'Default'
    }

    const isAutoApprovalThresholdError = formik.values.quotationAutoApprovalThreshold !== null &&
        !formik.values.quotationAutoApprovalThreshold &&
        formik.values.quotationAutoApproval


    const showErrorMessage = () => {
        if (isAutoApprovalThresholdError) {
            return (
                <div className="threshold-error">
                    <WarningAmberOutlined />
                    <span> This field cannot be empty </span>
                </div>
            )
        }
        return null
    }


    return (
        <form onSubmit={formik.handleSubmit} className="workflow-configuration-container">
            <h1 className="workflow-configuration-title">Edit Default Workflow</h1>
            <div className="workflow-configuration-content">
                <div className="workflow-configuration-first-container">
                    <div className="workflow-configuration-approvers-container">
                        <div className="edit-title">Approvers</div>
                        <div className="edit-value">
                            {isDefaultWorkflow() || numberOfGuildAdmins === approvers.length ?
                                'All Guild Admins' : `${approvers.length} Guild Admins`}
                        </div>
                    </div>
                    <div className="workflow-configuration-requesters-container">
                        <div className="edit-title">Requestors</div>
                        <div className="edit-value">
                            {isDefaultWorkflow() || numberOfGuildMembers === requestors.length ?
                                'All Guild Members' : `${requestors.length} Guild Members`}
                        </div>
                    </div>
                </div>
                <div className="workflow-configuration-second-container">
                    <div className="workflow-configuration-approval-container">
                        <div className="edit-value">Request Approval</div>
                        <div>
                            <CustomSwitch
                                disabled={false}
                                value={WorkflowConfigurationStage.AUTO_APPROVE_SERVICE_REQUEST}
                                isChecked={formik.values.serviceRequestAutoValidation}
                                toggleChecked={(e) => formik.setFieldValue('serviceRequestAutoValidation', e.target.checked)}
                                dataTestid={`${WorkflowConfigurationStage.AUTO_APPROVE_SERVICE_REQUEST}-${getServiceName(
                                    formik.values.service
                                )}`}
                            />
                        </div>
                    </div>
                    <div className="workflow-configuration-quotation-approval-container">
                        <div className="edit-value">Quotation Approval</div>
                        <div>
                            <CustomSwitch
                                disabled={false}
                                value={WorkflowConfigurationStage.AUTO_APPROVE_QUOTATION}
                                isChecked={formik.values.quotationAutoApproval}
                                toggleChecked={(e) => formik.setFieldValue('quotationAutoApproval', e.target.checked)}
                                dataTestid={`${WorkflowConfigurationStage.AUTO_APPROVE_QUOTATION}-${getServiceName(
                                    formik.values.service
                                )}`}
                            />
                            <div>
                                <div className="quotation-options">
                                    <div className="radio-group-1">
                                        <Radio
                                            className="radio-button"
                                            checked={formik.values.quotationAutoApprovalThreshold === null}
                                            onChange={() => formik.setFieldValue('quotationAutoApprovalThreshold', null)}
                                            disabled={!formik.values.quotationAutoApproval}
                                        />
                                        <span className="edit-title">Always</span>
                                    </div>
                                    <div className="radio-group-2">
                                        <Radio
                                            className="radio-button"
                                            data-testid="quotation-input"
                                            checked={formik.values.quotationAutoApprovalThreshold !== null}
                                            onChange={() => formik.setFieldValue('quotationAutoApprovalThreshold', '')}
                                            disabled={!formik.values.quotationAutoApproval}
                                        />
                                        <div className="credits-box">
                                            <span className="edit-title">When quotation is lower than:</span>
                                            <TextField
                                                data-testid="quotation-text-field"
                                                size="small"
                                                autoComplete="off"
                                                className="quotation-threshold-input"
                                                label={formik.values.quotationAutoApprovalThreshold ? '' : 'Number of Credits'}
                                                InputProps={{
                                                    inputProps: {min: 0}
                                                }}
                                                InputLabelProps={{
                                                    className: 'custom-label',
                                                    shrink: false,
                                                }}
                                                type="number"
                                                value={formik.values.quotationAutoApprovalThreshold || ''}
                                                onChange={(e) =>
                                                    formik.setFieldValue('quotationAutoApprovalThreshold', e.target.value)
                                                }
                                                error={isAutoApprovalThresholdError}
                                                helperText={showErrorMessage()}
                                                disabled={!formik.values.quotationAutoApproval || formik.values.quotationAutoApprovalThreshold === null}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="workflow-configuration-form-footer">
                    <Button
                        className="workflow-configuration-form-previous"
                        variant="text"
                        type="button"
                        onClick={() => navigate(generatePath(ROUTES.workflowConfiguration.path))}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        color="primary"
                        variant="contained"
                        type="submit"
                        className="workflow-configuration-form-next"
                        data-testid={`save-changes-button-for-${getServiceName(formik.values.service)}`}
                        disabled={isAutoApprovalThresholdError}
                    >
                        Save Changes
                    </LoadingButton>
                </div>
            </div>
        </form>
    )
}

export default ServiceWorkflowConfigurationEditDetails
