import {createSelector} from '@reduxjs/toolkit'
import {createApi, retry} from '@reduxjs/toolkit/dist/query/react'
import {GUILD_API_ENDPOINT} from '../constants/backendUrls'
import {User} from '../user/model/User'
import {axiosBaseQuery} from '../rtkQueryUtils'
import {IdType} from '../util/models/IdType'
import {Guild} from './models/Guild'
import {GuildCreationModel} from './models/GuildCreationModel'
import {Sharing} from './itemSharing/model/Sharing'
import {GuildSharingSummary} from './itemSharing/model/GuildSharingSummary'
import {GuildSharingCreation} from './itemSharing/model/GuildSharingCreation'
import {SEARCH_ITEM_TAG, searchAPI} from '../search/rtkSearchApi'
import {WorkflowConfiguration} from './workflowConfiguration/model/WorkflowConfiguration'
import {Transaction} from './guildManagement/credit/models/Transaction'
import {selectGetUser, USER_TAG} from '../user/rtkUserApi'

export const CURRENT_GUILD_TAG = 'CurrentGuild'
export const ITEMS_SHARING_TO_OTHER = 'sharing'

export const WORKFLOW_CONFIGURATION = 'workflowConfiguration'

interface PageableResponse<T> {
    number: number
    size: number
    totalElements: number
    totalPages: number
    content: T[]
}

export const guildAPI = createApi({
    reducerPath: 'guildAPI',
    keepUnusedDataFor: 0,
    tagTypes: [CURRENT_GUILD_TAG, USER_TAG, ITEMS_SHARING_TO_OTHER, WORKFLOW_CONFIGURATION],
    baseQuery: retry(axiosBaseQuery({baseUrl: `${GUILD_API_ENDPOINT}/api/v1/datx/`}), {maxRetries: 0}),
    endpoints: (builder) => ({
        getGuild: builder.query<Guild, IdType | undefined>({
            query: (guildId) => ({
                url: `guilds/${guildId}`,
                method: 'GET'
            }),
            transformResponse: (response: any) => response.data,
            providesTags: [CURRENT_GUILD_TAG]
        }),
        getGuildSummary: builder.query<Guild, IdType | undefined>({
            query: (guildId) => ({
                url: `guilds/${guildId}/summary`,
                method: 'GET'
            }),
            transformResponse: (response: any) => response.data,
            providesTags: [CURRENT_GUILD_TAG]
        }),
        getGuildTransactions: builder.query<
            PageableResponse<Transaction>,
            {pageNumber?: number; pageSize?: number; guildId: IdType | undefined}
        >({
            query: ({pageNumber = 0, pageSize = 20, guildId}) => ({
                url: `guilds/${guildId}/transactions?page=${pageNumber}&size=${pageSize}`,
                method: 'GET'
            }),
            transformResponse: (response: any) => response.data,
            providesTags: [CURRENT_GUILD_TAG],
            keepUnusedDataFor: 30
        }),
        inviteUserToGuild: builder.mutation<Guild, any>({
            query: ({guildId, userEmail}) => ({
                url: `guilds/${guildId}/users/${userEmail}`,
                method: 'POST'
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: (response: any) => response,
            invalidatesTags: [CURRENT_GUILD_TAG]
        }),
        removeUserFromGuild: builder.mutation<Guild, any>({
            query: ({guildId, memberId}) => ({
                url: `guilds/${guildId}/users/${memberId}`,
                method: 'DELETE'
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: (response: any) =>
                response?.data?.errorMessage || 'Something went wrong during the removal of the user',
            invalidatesTags: [CURRENT_GUILD_TAG]
        }),
        rejectGuildInvitation: builder.mutation({
            query: ({guildId, invitedUserId, notificationId}) => ({
                url: `guilds/${guildId}/users/${invitedUserId}/status/rejected`,
                method: 'PATCH',
                data: {notificationId}
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: (response: any) =>
                response?.data?.errorMessage || 'Something went wrong when rejecting the guild invitation'
        }),
        acceptGuildInvitation: builder.mutation({
            query: ({guildId, invitedUserId, notificationId}) => ({
                url: `guilds/${guildId}/users/${invitedUserId}/status/active`,
                method: 'PATCH',
                data: {notificationId}
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: (response: any) =>
                response?.data?.errorMessage || 'Something went wrong when accepting the guild invitation',
            invalidatesTags: [USER_TAG]
        }),
        promoteGuildUserToGuildAdmin: builder.mutation<
            Guild,
            {
                guildId: IdType | undefined
                memberId: IdType | undefined
            }
        >({
            query: ({guildId, memberId: memberOAuthId}) => ({
                url: `guilds/${guildId}/users/${memberOAuthId}/admin`,
                method: 'PATCH'
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: () => 'Error getting promoting user to admin',
            invalidatesTags: [CURRENT_GUILD_TAG]
        }),
        createGuild: builder.mutation<any, GuildCreationModel>({
            query: (guildCreationModel) => ({
                url: 'guilds',
                method: 'POST',
                data: guildCreationModel
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: () => 'There was a problem submitting the guild creation request'
        }),
        getSharingFromSourceGuild: builder.query<GuildSharingSummary[], IdType | undefined>({
            query: (guildId) => ({
                url: `sharing/from/${guildId}`,
                method: 'GET'
            }),
            transformResponse: (response: any) => response.data,
            providesTags: [ITEMS_SHARING_TO_OTHER]
        }),
        getSharingToTargetGuild: builder.query<GuildSharingSummary[], IdType | undefined>({
            query: (guildId) => ({
                url: `sharing/to/${guildId}`,
                method: 'GET'
            }),
            transformResponse: (response: any) => response.data
        }),
        getSharingById: builder.query<Sharing, IdType>({
            query: (sharingId) => ({
                url: `sharing/${sharingId}`,
                method: 'GET'
            }),
            transformResponse: (response: any) => response.data,
            providesTags: [ITEMS_SHARING_TO_OTHER]
        }),
        createNewSharing: builder.mutation<GuildSharingSummary, GuildSharingCreation>({
            query: (sharing) => ({
                url: 'sharing',
                method: 'POST',
                data: sharing
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: (response: any) =>
                response?.data?.errorMessage || 'Something went wrong during the sharing request',
            invalidatesTags: [ITEMS_SHARING_TO_OTHER]
        }),
        updateItemsSharing: builder.mutation({
            query: ({sharingId, organizationId}: {sharingId: IdType; organizationId: IdType}) => ({
                url: `sharing/${sharingId}/items/organization/${organizationId}`,
                method: 'PUT'
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: (response: any) =>
                response?.data?.errorMessage || 'Something went wrong during updating the sharing',
            invalidatesTags: [ITEMS_SHARING_TO_OTHER]
        }),
        updateDefinitionsSharing: builder.mutation({
            query: ({sharingId, definitions}: {sharingId: IdType; definitions: IdType[]}) => ({
                url: `sharing/${sharingId}/definitions`,
                method: 'PUT',
                data: definitions
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: (response: any) =>
                response?.data?.errorMessage || 'Something went wrong during updating the sharing',
            invalidatesTags: [ITEMS_SHARING_TO_OTHER]
        }),
        stopSharing: builder.mutation({
            query: ({sharingId, organizationId}: {sharingId: IdType; organizationId: IdType}) => ({
                url: `sharing/${sharingId}/items/organization/${organizationId}`,
                method: 'DELETE'
            }),
            transformResponse: () => 'Successfully stopped sharing',
            transformErrorResponse: (response: any) =>
                response?.data?.errorMessage || 'Something went wrong during the stop sharing request',
            invalidatesTags: [ITEMS_SHARING_TO_OTHER]
        }),
        addSpecificItemsToShare: builder.mutation({
            query: ({sharingId, itemIdsOfItemList}: {sharingId: IdType; itemIdsOfItemList: IdType[] | undefined}) => ({
                url: `sharing/${sharingId}/items`,
                method: 'POST',
                data: itemIdsOfItemList
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: (response: any) =>
                response?.data?.errorMessage || 'Something went wrong during itemlist sharing request',
            invalidatesTags: [ITEMS_SHARING_TO_OTHER],
            //As the result cache for the `searchItems` rtk query with specific items filters refreshes after 30s, we can promptly update the cache data after sharing items list to view the shared table
            async onQueryStarted(_, {dispatch, queryFulfilled}) {
                await queryFulfilled
                dispatch(searchAPI.util.invalidateTags([SEARCH_ITEM_TAG]))
            }
        }),
        removeSpecificItemsToShare: builder.mutation({
            query: ({sharingId, itemIdsOfItemList}: {sharingId: IdType; itemIdsOfItemList: IdType[] | undefined}) => ({
                url: `sharing/${sharingId}/items`,
                method: 'DELETE',
                data: itemIdsOfItemList
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: (response: any) =>
                response?.data?.errorMessage || 'Something went wrong during itemlist unsharing request',
            invalidatesTags: [ITEMS_SHARING_TO_OTHER],
            //As the result cache for the `searchItems` rtk query with specific items filters refreshes after 30s, we can promptly update the cache data after sharing items list to view the shared table
            async onQueryStarted(_, {dispatch, queryFulfilled}) {
                await queryFulfilled
                dispatch(searchAPI.util.invalidateTags([SEARCH_ITEM_TAG]))
            }
        }),
        getWorkflowConfigurations: builder.query<WorkflowConfiguration[], IdType | undefined>({
            query: (guildId) => ({
                url: `workflowConfiguration/guild/${guildId}`,
                method: 'GET'
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: (response: any) =>
                response?.data?.message ||
                response?.data ||
                'Something went wrong during getting the workflow configurations',
            providesTags: [WORKFLOW_CONFIGURATION]
        }),
        getWorkflowConfiguration: builder.query<WorkflowConfiguration, IdType>({
            query: (workflowConfigurationId) => ({
                url: `workflowConfiguration/${workflowConfigurationId}`,
                method: 'GET'
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: (response: any) =>
                response?.data?.message || response?.data || 'Something went wrong during getting the workflow configuration',
            providesTags: [WORKFLOW_CONFIGURATION]
        }),
        createWorkflowConfiguration: builder.mutation({
            query: ({newWorkflowConfiguration}: {newWorkflowConfiguration: WorkflowConfiguration}) => ({
                url: 'workflowConfiguration',
                method: 'POST',
                data: newWorkflowConfiguration
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: (response: any) =>
                response?.data?.errorMessage ||
                response?.data?.message ||
                response?.data ||
                'Something went wrong during creation of workflow configuration',
            invalidatesTags: [WORKFLOW_CONFIGURATION]
        }),
        updateWorkflowConfiguration: builder.mutation({
            query: ({workflowConfiguration}: {workflowConfiguration: WorkflowConfiguration}) => ({
                url: `workflowConfiguration/${workflowConfiguration.id}`,
                method: 'POST',
                data: workflowConfiguration
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: (response: any) =>
                response?.data?.errorMessage ||
                response?.data?.message ||
                response?.data ||
                'Something went wrong during updating the workflow configuration',
            invalidatesTags: [WORKFLOW_CONFIGURATION]
        })
    })
})

export const selectGuildWhereAdmin = createSelector(selectGetUser, (getUserResult) =>
    getUserResult.data?.guildList.filter((guild) => guild.guildAdmin)
)

export const {
    useGetGuildQuery,
    useGetGuildSummaryQuery,
    useGetGuildTransactionsQuery,
    useInviteUserToGuildMutation,
    useRemoveUserFromGuildMutation,
    useRejectGuildInvitationMutation,
    useAcceptGuildInvitationMutation,
    usePromoteGuildUserToGuildAdminMutation,
    useCreateGuildMutation,
    useGetSharingFromSourceGuildQuery,
    useGetSharingToTargetGuildQuery,
    useGetSharingByIdQuery,
    useCreateNewSharingMutation,
    useUpdateItemsSharingMutation,
    useUpdateDefinitionsSharingMutation,
    useStopSharingMutation,
    useAddSpecificItemsToShareMutation,
    useRemoveSpecificItemsToShareMutation,
    useGetWorkflowConfigurationsQuery,
    useGetWorkflowConfigurationQuery,
    useCreateWorkflowConfigurationMutation,
    useUpdateWorkflowConfigurationMutation,
} = guildAPI
